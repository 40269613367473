import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Grid } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import '../index.css'

import meta from '../../meta/config'
import Header from './Header'
import Footer from './Footer'

const Main = styled.main`
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Main>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: meta.siteDescription },
            { name: 'keywords', content: meta.siteKeywords.join(', ') },
          ]}>
          <html lang="en" />
        </Helmet>
        <Header />
        <Grid>{children}</Grid>
        <Footer />
      </Main>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
