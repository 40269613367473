import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Nav = styled.nav`
  background-color: var(--gunmetal);
  color: var(--warm-yellow);
  padding: 1em;
  a {
    color: var(--warm-yellow);
  }
  margin-bottom: 1em;
`
const Inner = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 160px) repeat(auto-fit, 80px);
`

export default function Header() {
  return (
    <Nav>
      <Inner className="container">
        <span>Rob's Mob</span>
        <Link to="/">Home</Link>
        <Link to="/trips">All trips</Link>
      </Inner>
    </Nav>
  )
}
