import React from 'react'
import styled from 'styled-components'

const Base = styled.footer`
  background-color: var(--gunmetal);
  padding: 0.75em;
  a {
    font-size: 14px;
    color: var(--warm-yellow);
  }
`

export default function Footer() {
  return (
    <Base>
      <div className="container">
        <div className="row">
          <div className="col-md-7 col-sm-7" />
          <div className="col-md-5 col-sm-5 col-xs-12 text-right">
            <a
              href="https://doomsdaytuna.com/?utm_source=robsmob"
              target="_blank"
              rel="noopener noreferrer">
              Website by Doomsday Tuna
            </a>
          </div>
        </div>
      </div>
    </Base>
  )
}
