module.exports = {
  siteTitle: "Rob's Mob", // Site title.
  siteTitleAlt: "Rob's Mob", // Alternative site title for SEO.
  siteLogo: '/logos/logo-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://robsmob.doomsdaytuna.io', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: "Rob's Mob", // Website description used for RSS feeds/meta description tag.
  siteKeywords: ["rob's mob"], // Website keywords.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTrackingID: '', // GTM tracking ID.
  disqusShortname: 'robs-mob', // Disqus shortname.
  userName: 'Robyn Cola',
  userTwitter: '',
  userLocation: 'Bendigo, Victoria',
  userDescription: '',
  copyright: `Copyright © Rob's Mob ${new Date().getFullYear()}. All Rights Reserved.`, // Copyright string for the footer of the website and RSS feed.
  themeColor: '#00d1b2', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}
