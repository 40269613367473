import React, { Component } from 'react'
import styled from 'styled-components'

const Base = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1px;
`
const Header = styled.div`
  grid-column: 1/-1;
  text-align: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
`
const Day = styled.div`
  cursor: pointer;
  user-select: none;
  &:nth-child(2n + 1) {
    background: #efefef;
  }
  &.other-month {
    color: lightgrey;
  }
  &.selected {
    background: lightblue;
  }
  &:hover {
    background: #aaeaff;
  }
`
const Button = styled.button`
  border: none;
  box-shadow: 0 0px 1px #a58888;
  &:focus {
    outline: none;
    box-shadow: 0 0px 2px 1px #49b8bd;
  }
`
const dayNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat']

export default class Calendar extends Component {
  state = {
    selectStart: null,
    selectEnd: null,
  }
  handleMouseDown = (date, e) => {
    this.setState({ selectStart: date, selectEnd: date })
  }
  handleMouseOver = date => {
    if (this.state.selectStart == null) return
    this.setState({ selectEnd: date })
  }
  handleMouseUp = () => {
    const { selectStart, selectEnd } = this.state
    if (selectStart != null && selectEnd != null && this.props.onRangeSelect) {
      this.props.onRangeSelect(selectStart, selectEnd)
    }
    this.setState({ selectStart: null, selectEnd: null })
  }
  render() {
    const {
      year,
      month,
      selectedDates,
      onClick,
      onPreviousMonth,
      onNextMonth,
      renderDate,
    } = this.props
    const { selectStart, selectEnd } = this.state
    const startDate = new Date(year, month, 1, 0, 0, 0, 0)
    const date = new Date(startDate)
    const days = []
    do {
      days.push(new Date(date))
      date.setDate(date.getDate() + 1)
    } while (date.getDate() !== 1)
    days.splice(
      0,
      0,
      ...Array(days[0].getDay())
        .fill(null)
        .map((_, i) => {
          const date = new Date(days[0])
          date.setDate(date.getDate() - i - 1)
          return date
        })
        .reverse()
    )
    days.push(
      ...Array(6 - days[days.length - 1].getDay())
        .fill(null)
        .map((_, i) => {
          const date = new Date(days[days.length - 1])
          date.setDate(date.getDate() + i + 1)
          return date
        })
    )
    return (
      <Base>
        <Header>
          <Button onClick={() => onPreviousMonth && onPreviousMonth(startDate)}>
            {'<'}
          </Button>
          <h3>
            {new Intl.DateTimeFormat('default', {
              year: 'numeric',
              month: 'long',
            }).format(startDate)}
          </h3>
          <Button onClick={() => onNextMonth && onNextMonth(startDate)}>
            {'>'}
          </Button>
        </Header>
        {dayNames.map(day => (
          <div key={day}>{day}</div>
        ))}
        {days.map(date => (
          <Day
            onClick={() => onClick(date)}
            onMouseDown={e => this.handleMouseDown(date, e)}
            onMouseUp={this.handleMouseUp}
            onMouseOver={e => this.handleMouseOver(date, e)}
            key={date.toString()}
            className={
              (date.getMonth() === startDate.getMonth() ? '' : 'other-month ') +
              (selectedDates.includes(date) ||
              (selectStart &&
                date >= selectStart &&
                (selectEnd && date <= selectEnd))
                ? 'selected'
                : ' ') // TODO: Fix different date identities in `selectDates`
            }>
            {date.getDate()}
            {renderDate(date)}
          </Day>
        ))}
      </Base>
    )
  }
}
