import React, { Component } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Calendar from '../components/Calendar'

export default class Home extends Component {
  state = {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    selectedDates: [],
  }
  handleClickDate = date => {
    this.setState({ month: date.getMonth() })
  }
  handleDateRangeSelect = (start, end) => {
    console.log(start, end)
  }
  handlePrevMonth = date => {
    var nextDate = new Date(date)
    nextDate.setMonth(nextDate.getMonth() - 1)
    this.setState({ year: nextDate.getFullYear(), month: nextDate.getMonth() })
  }
  handleNextMonth = date => {
    var nextDate = new Date(date)
    nextDate.setMonth(nextDate.getMonth() + 1)
    this.setState({ year: nextDate.getFullYear(), month: nextDate.getMonth() })
  }
  render() {
    const { year, month, selectedDates } = this.state
    const {
      data: { trips },
    } = this.props
    return (
      <Layout>
        <h1>Rob's Mob!</h1>
        <Calendar
          year={year}
          month={month}
          selectedDates={selectedDates}
          onClick={this.handleClickDate}
          onRangeSelect={this.handleDateRangeSelect}
          onPreviousMonth={this.handlePrevMonth}
          onNextMonth={this.handleNextMonth}
          renderDate={date => {
            const tripsOnDay = trips.edges.filter(
              ({
                node: {
                  frontmatter: { startDate, endDate },
                },
              }) => new Date(startDate) <= date && new Date(endDate) >= date
            )
            return (
              <React.Fragment>
                {tripsOnDay.map(
                  ({
                    node: {
                      coverImage,
                      frontmatter: { slug, title },
                    },
                  }) => (
                    <Link to={slug} key={slug}>
                      {coverImage && (
                        <Img fluid={coverImage.childImageSharp.fluid} />
                      )}
                      <p>{title}</p>
                    </Link>
                  )
                )}
              </React.Fragment>
            )
          }}
        />
      </Layout>
    )
  }
}

export const CalendarQuery = graphql`
  query CalendarPage {
    trips: allMarkdownRemark(
      filter: { frontmatter: { tags: { eq: "trip" } } }
    ) {
      edges {
        node {
          coverImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          frontmatter {
            slug
            title
            startDate
            endDate
          }
        }
      }
    }
  }
`
